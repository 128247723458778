const openAndDownloadPdf = (base64pdf: string, fileName?: string): void => {
  // create Blob from base64 string
  const byteCharacters = atob(base64pdf)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: 'application/pdf' })

  // create Blob URL
  const blobUrl = URL.createObjectURL(blob)

  // open PDF in new tab
  window.open(blobUrl, '_blank')

  // download PDF
  if (fileName) {
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // clean up Blob URL after short delay
  setTimeout(() => {
    URL.revokeObjectURL(blobUrl)
  }, 100)
}

const isValidBase64Pdf = (base64String: string): boolean => {
  if (!base64String.startsWith('JVBERi0')) {
    return false
  }

  try {
    atob(base64String.slice(0, 50))
    return true
  } catch (e) {
    return false
  }
}

const PdfUtils = {
  openAndDownloadPdf,
  isValidBase64Pdf,
}

export default PdfUtils
