import React from 'react'
import { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InvoiceCardProps } from './InvoiceCard.model'
import FormatUtils from '../../utilities/utils/format'
import Spacer from '../Spacer'
import Typography from '../Typography'
import Icon, { Icons } from '../Icon'
import {
  LabelWrapperLeft,
  LabelWrapperRight,
  DeliveryNoteLineCardWrapper,
  LeftIconWrapper,
  CardTitleWrapper,
  StyledCardWrapper,
} from './InvoiceCard.styles'
import { CarrierStackParamList } from '../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ProducerStackParamList } from '../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import TouchableCard from '../TouchableCard'
import { INVOICE_STATUSES, InvoiceStatusEnum } from '../../../enums/invoice'
import BadgeStatus from '../BadgeStatus'
import { InterfaceColors } from '../../utilities/styling/theme/theme'

const InvoiceCard = ({ invoice }: InvoiceCardProps) => {
  const { getAccessInfos } = useAuthenticationContext()
  const theme = useTheme()
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const onClickCard = (invoiceId: string) => {
    if (getAccessInfos().carrierId) {
      navigationCarrier.navigate('EditInvoice', {
        invoiceId,
      })
    } else {
      navigationProducer.navigate('EditInvoice', {
        invoiceId,
      })
    }
  }

  const invoiceStatusColors: Record<InvoiceStatusEnum, keyof InterfaceColors> = {
    [InvoiceStatusEnum.DRAFT]: 'text-dark-3',
    [InvoiceStatusEnum.SEND]: 'color-warning',
    [InvoiceStatusEnum.PAID]: 'text-dark-1',
    [InvoiceStatusEnum.UNPAID]: 'color-danger',
  }

  const status = INVOICE_STATUSES.find((s) => s.value === invoice.status)
  const statusColor = invoiceStatusColors[invoice.status]

  return (
    <StyledCardWrapper onPress={() => onClickCard(invoice.id)}>
      <TouchableCard>
        <DeliveryNoteLineCardWrapper>
          <LeftIconWrapper>
            <Icon
              type={Icons.MaterialCommunityIcons}
              name="file-document-multiple-outline"
              size={theme.spacingUnit * 3}
              color="color-primary"
            />
          </LeftIconWrapper>
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <LabelWrapperLeft>
            <CardTitleWrapper>
              <Typography.CardTitle>
                Facture #{invoice.displayIdentifier} - {invoice.recipient.legalName}
              </Typography.CardTitle>
            </CardTitleWrapper>
            <BadgeStatus label={status?.label || ''} color={statusColor} />
          </LabelWrapperLeft>
          <Spacer size={3} axis={Spacer.AxisEnum.HORIZONTAL} />
          <LabelWrapperRight>
            <Typography.BodyExtraSmall colorName="text-dark-3">Total HT</Typography.BodyExtraSmall>
            <Typography.BodySmall bold colorName="text-dark-1">
              {FormatUtils.formatPrice(invoice.totalAmounts.totalHT)}
            </Typography.BodySmall>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {`Crée le ${FormatUtils.formatDate(
                invoice.createdDate.toString(),
                'Date',
              )} à ${FormatUtils.formatDate(invoice.createdDate.toString(), 'InputTime')}`}
            </Typography.BodySmall>
          </LabelWrapperRight>
        </DeliveryNoteLineCardWrapper>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default InvoiceCard
