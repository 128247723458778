import { Button, PageTitle, Spacer } from '../../../../components'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import InvoiceService from '../../../../services/invoice'
import { useCallback, useEffect, useState } from 'react'
import InvoiceCard from '../../../../components/InvoiceCard'
import CardListPaginated from '../../../../components/CardListPaginated'
import { Invoice } from '../../../../domain/Invoice'
import { ProducerStackParamList } from '../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { CarrierStackParamList } from '../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import {
  InvoiceFilterEnum,
  SelectedValues,
} from '../../../../components/FilterModule/FilterModule.model'
import { INVOICE_STATUSES, InvoiceStatusEnum } from '../../../../../enums/invoice'
import FilterModule from '../../../../components/FilterModule'
import DateFilter from '../../../../components/DateFilter'

const ListInvoiceScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const [invoiceList, setInvoiceList] = useState<Invoice[]>([])
  const [pageSize, setPageSize] = useState(5)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedFilters, setSelectedFilters] = useState<SelectedValues>({})
  const [currentlyUpdatingFilter, setCurrentlyUpdatingFilter] = useState<InvoiceFilterEnum | null>(
    null,
  )

  const [metricsFilters, setMetricsFilters] = useState(() => {
    const currentDate = new Date()
    return {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59),
    }
  })

  const { query: invoicesQuery } = InvoiceService.useGetInvoices({
    accountId: getAccessInfos().accountId,
    limit: pageSize,
    pageNumber: pageNumber,
    getByIssuer: true,
    recipientId: selectedFilters[InvoiceFilterEnum.CLIENT] as string | undefined,
    status: selectedFilters[InvoiceFilterEnum.STATUS] as InvoiceStatusEnum | undefined,
    start: metricsFilters.start,
    end: metricsFilters.end,
  })

  const {
    data: invoicesData,
    refetch: refetchInvoices,
    isLoading: invoicesLoading,
  } = invoicesQuery()

  useEffect(() => {
    if (invoicesData?.data) {
      setInvoiceList(invoicesData.data)
    }
  }, [invoicesData?.data])

  const getFilters = useCallback(() => {
    const getConditionalLabel = (filterKey: InvoiceFilterEnum) => {
      if (selectedFilters[filterKey]) {
        if (filterKey === InvoiceFilterEnum.CLIENT) {
          const selectedClient = invoiceList.find(
            (invoice) => invoice.recipient.id === selectedFilters[filterKey],
          )
          return `Client : ${selectedClient?.recipient.legalName || 'Sélectionné'}`
        } else if (filterKey === InvoiceFilterEnum.STATUS) {
          const selectedStatus = INVOICE_STATUSES.find(
            (status) => status.value.toString() === selectedFilters[filterKey]?.toString(),
          )
          return `Statut : ${selectedStatus?.label || 'Sélectionné'}`
        }
      }
      return filterKey === InvoiceFilterEnum.CLIENT ? 'par client' : 'par statut'
    }

    return [
      {
        key: InvoiceFilterEnum.CLIENT,
        active: !!selectedFilters[InvoiceFilterEnum.CLIENT],
        label: getConditionalLabel(InvoiceFilterEnum.CLIENT),
        options: Array.from(new Set(invoiceList.map((invoice) => invoice.recipient.id))).map(
          (id) => {
            const invoice = invoiceList.find((inv) => inv.recipient.id === id)
            return { label: invoice?.recipient.legalName || '', value: id }
          },
        ),
      },
      {
        key: InvoiceFilterEnum.STATUS,
        active: !!selectedFilters[InvoiceFilterEnum.STATUS],
        label: getConditionalLabel(InvoiceFilterEnum.STATUS),
        options: INVOICE_STATUSES.map((status) => ({ label: status.label, value: status.value })),
      },
    ]
  }, [invoiceList, selectedFilters, INVOICE_STATUSES])

  const onClickMetricsChangeDate = async (value?: number, exactDate?: any, paramDate?: string) => {
    let tempMetricsFilter = { start: metricsFilters.start, end: metricsFilters.end }
    if (exactDate && paramDate) {
      if (paramDate === 'start') {
        tempMetricsFilter.start = exactDate
      } else if (paramDate === 'end') {
        tempMetricsFilter.end = exactDate
      }
    } else {
      let currentMonthNew = metricsFilters.start.getMonth()
      let currentYear = metricsFilters.start.getFullYear()
      currentMonthNew = currentMonthNew + (value || 0)

      if (currentMonthNew < 0) {
        currentMonthNew = 11
        currentYear = currentYear - 1
      }

      if (currentMonthNew > 11) {
        currentMonthNew = 0
        currentYear = currentYear + 1
      }

      const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

      const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

      tempMetricsFilter = { start: newStartDate, end: newEndDate }
    }

    await setMetricsFilters(tempMetricsFilter)
    if (!exactDate) {
      refetchInvoices()
    }
  }

  useEffect(() => {
    refetchInvoices()
  }, [metricsFilters])

  const onChangePage = async (pageNumber: number) => {
    setPageNumber(pageNumber)
    refetchInvoices()
  }

  const handleFilterUpdate = async (filterKey: InvoiceFilterEnum, selectedValue?: any) => {
    if (selectedValue === undefined) {
      setCurrentlyUpdatingFilter(currentlyUpdatingFilter === filterKey ? null : filterKey)
    } else {
      setCurrentlyUpdatingFilter(null)
      setSelectedFilters((prev) => ({
        ...prev,
        [filterKey]: selectedValue,
      }))
      setPageNumber(1)
      await refetchInvoices()
    }
  }

  const handleFilterDelete = async (filterKey: InvoiceFilterEnum) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev }
      delete newFilters[filterKey]
      return newFilters
    })
    setCurrentlyUpdatingFilter(null)
    setPageNumber(1)
    await refetchInvoices()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ListInvoiceScreenPageTitle />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <DateFilter
          onClickMetricsChangeDate={onClickMetricsChangeDate}
          metricsFilters={metricsFilters}
        />

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <FilterModule
          filters={getFilters()}
          currentlyUpdating={currentlyUpdatingFilter}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <CardListPaginated
          data={invoiceList}
          pagination={invoicesData?.pagination}
          element={(invoice: Invoice) => <InvoiceCard key={invoice.id} invoice={invoice} />}
          emptyMessage="Aucune facture n'a été créée"
          onChangePage={onChangePage}
          isLoading={invoicesLoading}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

function ListInvoiceScreenPageTitle() {
  const { isCurrentUserRootAdmin, getAccessInfos } = useAuthenticationContext()

  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  function onClickGenerate() {
    if (getAccessInfos().carrierId) {
      navigationCarrier.navigate('CreateInvoice')
    } else {
      navigationProducer.navigate('CreateInvoice')
    }
  }

  return isCurrentUserRootAdmin() ? (
    <PageTitle
      title={`Factures`}
      buttonRight={<Button label="Générer" onPress={onClickGenerate} />}
    />
  ) : (
    <PageTitle title={`Factures`} />
  )
}

export default ListInvoiceScreen
