import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, PageTitle, Row, SectionTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Order, Pagination } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

import FormatUtils from '../../../utilities/utils/format'
import OrderClientService from '../../../services/client/order'
import CardSettings from '../../../components/CardSettings'
import { Switch } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { ShelfLabel } from '../../../domain/ShelfLabel'
import ShelfLabelClientService from '../../../services/client/shelfLabel'
import CardListPaginated from '../../../components/CardListPaginated'
import ShelfLabelCard from '../../../components/ShelfLabelCard'
import PdfUtils from '../../../utilities/utils/pdf'
import { queryClient } from '../../../utilities/queryClient'
import { StackNavigationProp } from '@react-navigation/stack'
import { View } from 'react-native'
import { useForm, useWatch } from 'react-hook-form'
import RhfTextInput from '../../../components/RhfTextInput'
import RhfDropdown from '../../../components/RhfDropdown'
import { ProductEnums } from '../../../../enums'
import { StyledGainBox } from './ShelfLabelCard.styles'

const ClientEditShelfLabelScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ClientStackParamList, 'ClientEditShelfLabel'>>()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()

  const [withPrices, setWithPrices] = useState<boolean>(true)
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
  const { getAccessInfos } = useAuthenticationContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { shelfLabelId } = route?.params ?? {
    shelfLabelId: '',
  }

  const {
    data: shelfLabel,
    refetch: refetchShelfLabel,
    isInitialLoading: shelfLabelLoading,
  } = useQuery<ShelfLabel, Error>(
    ['c_shelf_label'],
    () => ShelfLabelClientService.getOne(shelfLabelId),
    {
      keepPreviousData: false,
      enabled: shelfLabelId !== '',
    },
  )

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      title: '',
      subTitle: '',
      customerPrice: '',
      mesureType: ProductEnums.MESURE_TYPE.PIECE,
    },
  })

  const [margin, setMargin] = useState(0)

  // Utilisation de `useWatch` pour surveiller les changements des champs pertinents
  const watchedCustomerPrice = useWatch({
    control,
    name: 'customerPrice',
    defaultValue: '',
  })

  const watchedMesureType = useWatch({
    control,
    name: 'mesureType',
    defaultValue: undefined,
  })

  const calculateMargin = () => {
    if (!shelfLabel) return 0
    const newCustomerPrice = parseFloat((watchedCustomerPrice || '').replace(',', '.'))
    const mesureType = shelfLabel.cartBatch?.batch?.product?.mesureType
    const unitWeight = shelfLabel.cartBatch?.batch?.product?.unitWeight
    const vatRate = shelfLabel.cartBatch?.batch?.product?.vatRate
    let clientPrice = shelfLabel.clientPrice

    let customerPrice = newCustomerPrice

    if (
      watchedMesureType &&
      mesureType &&
      watchedMesureType !== mesureType &&
      unitWeight &&
      unitWeight !== 1
    ) {
      customerPrice = customerPrice * unitWeight
    }

    const computedMargin = customerPrice
      ? (customerPrice / (1 + (vatRate || 0) / 100) - (clientPrice || 0)) /
        (customerPrice / (1 + (vatRate || 0) / 100))
      : 0

    setMargin(computedMargin)
  }

  useEffect(() => {
    calculateMargin()
  }, [watchedCustomerPrice, watchedMesureType])

  useEffect(() => {
    if (shelfLabel) {
      reset({
        title: shelfLabel.title || '',
        subTitle: shelfLabel.subTitle || '',
        customerPrice: shelfLabel.customerPrice
          ? shelfLabel.customerPrice.toString().replace('.', ',')
          : '',
        mesureType: shelfLabel.mesureType ? shelfLabel.mesureType : undefined,
      })
    }
  }, [shelfLabel, reset])

  if (shelfLabelLoading || !shelfLabel) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  const orderId = shelfLabel.order?.id
  const tour = shelfLabel.order?.cart?.tour

  const onPressPrint = async () => {
    if (!orderId) return
    setIsLoading(true)
    try {
      const base64Response = await ShelfLabelClientService.getPdf(orderId, withPrices, [
        shelfLabelId,
      ])
      if (!PdfUtils.isValidBase64Pdf(base64Response)) {
        throw new Error("Le PDF reçu n'est pas valide")
      }
      PdfUtils.openAndDownloadPdf(base64Response, `order_${orderId}.pdf`)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressDeleteOne = async () => {
    setIsDeleteLoading(true)
    await ShelfLabelClientService.deleteOne(shelfLabelId)
    setIsDeleteLoading(false)
    await queryClient.invalidateQueries(['c_shelf_labels_order', orderId])

    navigation.goBack()
  }

  const onSaveShelfLabel = async (data: any) => {
    const formattedData = {
      title: data.title,
      subTitle: data.subTitle,
      customerPrice: parseFloat(data.customerPrice.replace(',', '.')),
      mesureType: data.mesureType,
    }
    setIsLoading(true)

    if (formattedData.title) {
      await ShelfLabelClientService.update(shelfLabel.id, formattedData)
      await queryClient.invalidateQueries(['c_shelf_labels_order', orderId])
      navigation.goBack()
    }
    setIsLoading(false)
  }

  const unitWeight = shelfLabel.cartBatch?.batch?.product?.unitWeight

  const displayUnitWeight = () => {
    const newMesureType = getValues('mesureType')
    const mesureType = shelfLabel.cartBatch?.batch?.product?.mesureType

    return (
      newMesureType !== mesureType &&
      unitWeight &&
      !(unitWeight === 1 && newMesureType === ProductEnums.MESURE_TYPE.PIECE)
    )
  }

  const displayMargin = () => {
    const newMesureType = getValues('mesureType')
    const mesureType = shelfLabel.cartBatch?.batch?.product?.mesureType

    return margin && !(!displayUnitWeight() && newMesureType !== mesureType)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper alignCenter>
        <PageTitle
          title="Modifier une étiquette"
          subtitle={`Commande #${FormatUtils.formatId(
            orderId,
          )}, livraison le ${FormatUtils.formatDate(tour?.start, 'FullDate+StartTime')}`}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <CardSettings
          title="Afficher le prix client sur l'étiquette"
          description={`Souhaitez-vous utiliser l'étiquette Harvy pour afficher le prix de vente client de vos produits`}
          children={
            <Switch
              trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
              onValueChange={() => setWithPrices(withPrices === true ? false : true)}
              value={withPrices === true}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        <RhfTextInput
          control={control}
          name="title"
          textInputLabel="Nom du produit"
          rules={{ required: true }}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <RhfTextInput control={control} name="subTitle" textInputLabel="Sous-titre (Calibre ...)" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Row>
          <Col xs={6} alignItems="center">
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall colorName="text-dark-1">
              Unité de mesure d'achat
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
            <Typography.Body bold colorName="text-dark-1">
              {FormatUtils.formatUnity(shelfLabel.cartBatch?.batch?.product?.mesureType)}
            </Typography.Body>
            {displayUnitWeight() && unitWeight && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <Typography.BodySmall colorName="text-dark-1">poids unitaire</Typography.BodySmall>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.3} />
                <Typography.Body bold colorName="text-dark-1">
                  {unitWeight.toString().replace('.', ',')} kg
                </Typography.Body>
              </>
            )}
          </Col>
          <Col xs={6} alignItems="center">
            <RhfDropdown
              control={control}
              name="mesureType"
              label={'Type de mesure à la vente'}
              multiple={false}
              // defaultValue={selectedMesureType?.value}
              optionsDefault={ProductEnums.MESURE_TYPES.map((mesureType) => ({
                ...mesureType,
              }))}
              zIndex={2000}
              zIndexInverse={40000}
              itemKey="mesureType"
            />
          </Col>
        </Row>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        <Row>
          <Col xs={3} alignItems="center">
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Typography.BodySmall colorName="text-dark-1">Prix d'achat</Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body bold colorName="text-dark-1">
              {FormatUtils.formatPrice(shelfLabel.clientPrice)}/
              {FormatUtils.formatUnity(shelfLabel?.mesureType)}
            </Typography.Body>
          </Col>
          <Col xs={6} alignItems="center">
            <RhfTextInput
              control={control}
              name="customerPrice"
              textInputLabel="Prix client"
              suffix={`€ TTC/${FormatUtils.formatUnity(
                getValues('mesureType') as ProductEnums.MESURE_TYPE,
              )}`}
            />
          </Col>
          <Col xs={3} alignItems="center">
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Typography.BodySmall colorName="text-dark-1">Marge</Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <StyledGainBox>
              <Typography.BodySmall semiBold>
                {displayMargin() ? FormatUtils.formatPercentage(margin) : '-'}
              </Typography.BodySmall>
            </StyledGainBox>
          </Col>
        </Row>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        {errors.title ? (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body colorName="color-danger">
              Un champ requis n'a pas été complété
            </Typography.Body>
          </>
        ) : null}

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <View style={{ flexDirection: 'row' }}>
          <Button
            label="Imprimer"
            onPress={() => onPressPrint()}
            loading={isLoading}
            disabled={isDirty}
          />
          <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={5} />
          <Button
            label="Sauvegarder"
            onPress={handleSubmit(onSaveShelfLabel)}
            loading={isLoading}
          />
        </View>

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        {getAccessInfos().clientIsCarrier && (
          <>
            <Button.Text
              label="Supprimer l'étiquette"
              onPress={() => onPressDeleteOne()}
              colorName="color-grey"
              loading={isDeleteLoading}
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientEditShelfLabelScreen
